/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react'
import { Item, Icon, Flag, Popup, Button, Modal, Label } from 'semantic-ui-react'
import Highlight from 'react-highlighter'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Link, useParams } from 'react-router-dom'
import { FEATURE, hasAccess } from '@douglas/baas-shared'
import { getDate } from '../../../helpers/date'
import { useUserContext } from '../../../contexts/User'
import { getDouglasPageLink } from '../../../helpers/getDouglasPageLink'
import ActionButtons from './ActionButtons'
import BriefingInfoComponent from './BriefingInfoComponent'
import BriefingModulesOverview from './BriefingModulesOverview'

import { isBaas15Active } from '../../../helpers/baasHelper'

const ExtraInfo = styled.div`
  display: inline-block;

  .ui.label {
    font-weight: 600;
    background-color: #f2f2f2;
  }
`

const FlagsWrapper = styled.div`
  display: inline-block;
  margin-left: 0.6em;
  i.flag:last-child {
    margin-right: 0;
  }
`

const SharedMessage = styled.div`
  height: 20px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export default function ListItem(props) {
  const [shared, setShared] = useState(false)
  const [showCode, setShowCode] = useState(false)
  const { role } = useUserContext()
  const canFork = hasAccess(role, FEATURE.fork)
  const params = useParams()

  const {
    _id,
    searchterm,
    type,
    title,
    description,
    shortDescription,
    dueDateGolive,
    country,
    children,
    leftButtons,
    isLocked,
    reporter,
    reporterFilter,
    identifier,
    status,
    shareScreen = false,
    contentDepartment,
  } = props
  const category = params.category || status
  const saveSearchterm = searchterm ? searchterm : ''

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 17) {
        setShowCode(!showCode)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Item>
      <Item.Content verticalAlign="middle">
        <Item.Header style={{ width: '100%' }}>
          <Highlight search={saveSearchterm}>{title}</Highlight>
          {isBaas15Active && (
            <>
              <BriefingInfoComponent briefing={props} />
              <BriefingModulesOverview briefing={props} />
            </>
          )}
        </Item.Header>
        <Item.Meta>
          <span className="cinema">
            <Highlight search={saveSearchterm}>{type.toUpperCase()}</Highlight>,{' '}
            <FlagsWrapper>
              {country && country.toLowerCase().search('_international') > 0 && <b>INTERNATIONAL</b>}
              {country && country.split(',').map(c => <Flag key={c} name={c.toLowerCase().replace('_new', '')} />)}
              {country && country.toLowerCase().search('_new') > 0 && <b>NEW</b>}
            </FlagsWrapper>
          </span>
        </Item.Meta>
        <Item.Description>
          {/* <b> 
            <Highlight search={saveSearchterm}>Placeholder</Highlight>
          </b> */}
          <Highlight search={saveSearchterm}>
            {shareScreen ? <div dangerouslySetInnerHTML={{ __html: description }} /> : shortDescription}
          </Highlight>
        </Item.Description>
        <Item.Extra>
          <ExtraInfo>
            <Label>
              <Icon name="clock outline" /> {getDate(dueDateGolive)}
            </Label>
          </ExtraInfo>
          <ExtraInfo>
            <Label>
              <Icon name="user" /> <Highlight search={reporterFilter}>{reporter}</Highlight>
            </Label>
          </ExtraInfo>
          {identifier && (
            <ExtraInfo>
              <Label>
                <Icon name="desktop" />
                <a
                  style={{ color: 'rgba(0,0,0,.4)', textDecoration: 'underline' }}
                  href={getDouglasPageLink(props)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="test-action-open-id-link"
                >
                  {identifier}
                </a>
              </Label>
            </ExtraInfo>
          )}
          {contentDepartment && (
            <ExtraInfo>
              <Label>
                <Icon name="building" />
                {contentDepartment}
              </Label>
            </ExtraInfo>
          )}
          {isLocked && (
            <Popup
              trigger={
                <ExtraInfo>
                  <Icon name="lock" /> Module configuration is locked
                </ExtraInfo>
              }
              content="This briefing has a locked module composition. You can't change the order of modules anymore.
              However, you can still fill-out the module form fields.
              If you want to change something about the module composition, ask an admin to re-open the briefing again."
              inverted
            />
          )}

          <ButtonWrapper>
            <div style={{ paddingTop: '20px' }}>
              {showCode && isBaas15Active && (
                <Modal
                  trigger={<Button icon="code" />}
                  header={'JSON Data of Briefing ' + _id}
                  content=<iframe
                    style={{ border: 'none' }}
                    title="code"
                    width="100%"
                    height="600px"
                    src={'/api/preview/getnice/' + _id}
                  />
                  actions={[{ key: 'done', content: 'Done', positive: true }]}
                />
              )}
              <ActionButtons {...props} />
              {leftButtons}
            </div>
            <div>
              <SharedMessage>{shared && 'Link copied!'}</SharedMessage>

              <Popup
                trigger={
                  <CopyToClipboard
                    text={`${window.location.origin}/briefings/${_id}/share`}
                    onCopy={() => setShared(true)}
                    className="test-action-share-button"
                  >
                    <Button icon="share alternate" />
                  </CopyToClipboard>
                }
                content="Share the briefing with a co-worker. The link will be automatically copied to the clipboard, when you click here."
                inverted
              />
              {canFork && (
                <Popup
                  trigger={
                    <Button as={Link} to={`/briefings/${category}/${_id}/fork`} icon="fork" className="test-action-fork-button" />
                  }
                  content="Copy this briefing and customize it for another language / campaign."
                  inverted
                />
              )}

              {children}
            </div>
          </ButtonWrapper>
        </Item.Extra>
      </Item.Content>
    </Item>
  )
}
