import { anyObjectFieldMatchingFilter } from './anyObjectFieldMatching'

export const memoFilterGroupedBriefings = (
  groupedBriefings,
  pagetype = '',
  reporter = '',
  searchterm = '',
  country = '',
  onlyOwnBriefings = false,
  userId,
  contentDepartment = ''
) => () => {
  const result = { ...groupedBriefings }
  Object.keys(result).forEach(key => {
    result[key] = result[key]
      .filter(
        b =>
          (b.type === pagetype || pagetype === 'all') &&
          (b.country === country || country === 'all') &&
          (b.contentDepartment === contentDepartment || contentDepartment === '') &&
          (b.reporter.search(reporter) !== -1 || reporter === '') &&
          (!onlyOwnBriefings || b.owner === userId || b.shared.includes(userId))
      )
      .filter(anyObjectFieldMatchingFilter(searchterm, false))
  })
  return result
}

export const memoFilterBriefings = (briefings, pagetype = '', reporter = '', searchterm = '') => () => {
  return briefings
    .filter(b => (b.type === pagetype || pagetype === 'all') && (b.reporter === reporter || reporter === ''))
    .filter(anyObjectFieldMatchingFilter(searchterm, false))
}
