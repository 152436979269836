import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, Icon, Button, Message, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {
  isNewShop,
  FEATURE,
  getAccessLevel,
  ACCESS_LEVEL,
  countryOptions as countryOptionsRaw,
  languageOptionsRaw,
  contentDepartment,
} from '@douglas/baas-shared'
import { useForm } from '../../hooks/useForm'
import { Input, Textarea, Select, ColorPicker, DatePicker } from '../../components/semantic/Form'
import HeaderHr from '../../components/semantic/HeaderHr'
import { SubContainer } from '../../components/SubContainer'
import { useUserContext } from '../../contexts/User'
import { isBaas15Active, createTypeOptions } from '../../helpers/baasHelper'

const templateOptions = [
  { key: 'nav', value: 'nav', text: 'With navigation' },
  { key: 'full', value: 'full', text: 'No navigation - page is centered' },
]

const specialBrandOptions = [
  { key: '', value: '', text: 'No special brand' },
  { key: 'chanel', value: 'chanel', text: 'Chanel' },
]
const typeOptionsRaw = [
  { key: 'contentpages', value: 'contentpages', text: 'Contentpage' },
  { key: 'promos', value: 'promos', text: 'Promotion' },
  { key: 'pdp', value: 'pdp', text: 'PDP+ content' },
  { key: 'pop', value: 'pop', text: 'POP+ content' },
  { key: 'pop_cp', value: 'pop_cp', text: 'POP+ without products' },
]

const contentDepartmentoptions = createTypeOptions(contentDepartment)

function Actionbar(props) {
  const { onSaveAndProceed, onSaveForLater } = props
  const [loadingState, setLoadingState] = useState({ save: false, proceed: false })
  const onSaveForLaterClicked = async () => {
    if (loadingState.save || loadingState.proceed) return
    setLoadingState({ save: true })
    await onSaveForLater()
    setLoadingState({ save: false })
  }
  const onSaveAndProceedClicked = async () => {
    if (loadingState.save || loadingState.proceed) return
    setLoadingState({ proceed: true })
    await onSaveAndProceed()
    setLoadingState({ proceed: false })
  }
  return (
    <Menu inverted style={{ height: '2em', minHeight: 'auto', margin: '0' }} className="action-bar-sticky">
      <Menu.Menu style={{ flexGrow: '1', textAlign: 'center', display: 'flex', justifyContent: 'center' }} />
      <Menu.Menu position="right">
        {!props.editMode && (
          <Menu.Item>
            <Button
              style={{ height: '2em', display: 'flex', alignItems: 'center' }}
              as={Link}
              secondary
              icon
              disabled={loadingState.save || loadingState.proceed}
              labelPosition="left"
              to="/briefings"
              id="cancel-button"
            >
              <Icon name="close" />
              Cancel
            </Button>
          </Menu.Item>
        )}
        <Menu.Item style={{ borderLeft: '1px solid white', borderRight: '1px solid white' }}>
          <Button
            style={{ height: '2em', paddingTop: '0', paddingBottom: '0' }}
            secondary
            icon
            onClick={onSaveForLaterClicked}
            loading={loadingState.save}
            disabled={loadingState.proceed}
            labelPosition="left"
            id="save-later-button"
          >
            <Icon name="save" />
            Save for later
          </Button>
        </Menu.Item>
        <Menu.Item style={{ paddingLeft: '0', paddingRight: '0' }}>
          <Button
            style={{ height: '2em', paddingTop: '0', paddingBottom: '0', borderRadius: '0', borderRight: '1px solid white' }}
            primary
            icon
            onClick={onSaveAndProceedClicked}
            loading={loadingState.proceed}
            disabled={loadingState.save}
            labelPosition="left"
            id="save-proceed-button"
          >
            <Icon name="angle double right" />
            Save & proceed to modules
          </Button>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

export default function BriefingForm({
  editMode = false,
  onSaveForLater,
  onSaveAndProceed,
  loading,
  error,
  initialValues = { type: 'contentpages', country: '', language: '' },
  title,
  icon,
  asText,
}) {
  const { role: userRole } = useUserContext()
  const pageTypeRef = useRef()
  const access = getAccessLevel(userRole, FEATURE.create)
  let countryOptions = countryOptionsRaw
  let languageOptions = languageOptionsRaw
  let typeOptions = typeOptionsRaw

  if (userRole === 15) {
    // influencer (15) can only use pdp and pop
    typeOptions = typeOptions.filter(c => !['promos', 'contentpages'].includes(c.key))
  }

  const [pageType, setPageType] = useState(initialValues.type)
  const [selectedCountry, setSelectedCountry] = useState(initialValues.country)
  const [selectedLanguage, setSelectedLanguage] = useState(initialValues.language)
  const isProductContent = pageType === 'pop' || pageType === 'pdp' || pageType === 'pop_cp'
  const { handleSubmit, ...formControls } = useForm(initialValues)

  useEffect(() => {
    if (selectedCountry) {
      const selectedCountryLanguage = countryOptionsRaw.find(c => c.value === selectedCountry).lang
      setSelectedLanguage(selectedCountryLanguage.length > 1 ? '' : selectedCountryLanguage[0])
    }
  }, [selectedCountry])

  useEffect(() => {
    pageTypeRef.current = pageType
  }, [pageType])

  const idFieldConfig = {
    label: 'Page ID',
    errorMessage: 'Please enter a valid Page ID (numbers, characters, - and _, length: 2-40)',
  }
  if (pageType === 'pdp') {
    idFieldConfig.label = 'Product ID'
    idFieldConfig.errorMessage = 'Please enter a valid Product ID ((M) + numbers, length: 6-11)'
  }
  if (pageType === 'pop' || pageType === 'pop_cp') {
    idFieldConfig.label = 'Category ID'
    idFieldConfig.errorMessage = 'Please enter a valid Category ID (numbers, characters and _, length: 2-12)'
  }

  const productIdValidate = useCallback(value => {
    let regex = new RegExp(/^([A-Za-z0-9_-]{2,40})$/)
    if (pageTypeRef.current === 'pdp') {
      regex = new RegExp(/^([M]{0,1}[0-9]{6,11})$/)
    }
    if (pageTypeRef.current === 'pop' || pageTypeRef.current === 'pop_cp') {
      regex = new RegExp(/^([-_A-Za-z0-9]{2,12})$/)
    }

    return regex.test(value)
  }, [])

  return (
    <React.Fragment>
      {!asText && (
        <Actionbar
          onSaveForLater={handleSubmit(onSaveForLater)}
          onSaveAndProceed={handleSubmit(onSaveAndProceed)}
          editMode={editMode}
        />
      )}
      <SubContainer style={asText ? { maxWidth: '100%' } : {}}>
        <HeaderHr style={asText ? { marginBottom: '2rem' } : { margin: '2rem 0' }} title={title} icon={icon} />
        <Form loading={loading} error={!!error}>
          <Message
            error
            header="Ouh :("
            content={`An error hapened while sending the form. Please contact Team-Pixel. (${error})`}
          />

          <Select
            validation={{ required: true }}
            errorMessage="Please pick a page type"
            label="Page type"
            name="type"
            description={
              <span>
                you <u>can not</u> edit this later
              </span>
            }
            options={typeOptions}
            multiple={false}
            asText={asText}
            disabled={editMode}
            hookIntoValueState={setPageType}
            {...formControls}
          />

          <Input
            validation={{ required: true, minLength: 5, maxLength: 50 }}
            errorMessage="Please enter a title with between 5 and 50 characters."
            label="Title"
            name="title"
            asText={asText}
            {...formControls}
          />
          <Textarea label="Description" name="description" asText={asText} {...formControls} />
          {!isProductContent && (
            <Form.Group widths="equal">
              <ColorPicker
                validation={{ required: true }}
                errorMessage="Please enter a valid hexcode or use the color picker."
                label="Primary color"
                name="colorPrimary"
                asText={asText}
                {...formControls}
              />
              <ColorPicker
                validation={{ required: true, pattern: /^#([A-Fa-f0-9]{6})$/ }}
                errorMessage="Please enter a valid hexcode or use the color picker."
                label="Secondary color"
                name="colorSecondary"
                asText={asText}
                {...formControls}
              />
            </Form.Group>
          )}
          <Form.Group widths="equal">
            <DatePicker
              validation={{ required: true }}
              errorMessage="Please enter a valid date."
              label="Due date go live"
              name="dueDateGolive"
              asText={asText}
              {...formControls}
            />
            <Input
              validation={{ required: true, validate: productIdValidate }}
              errorMessage={idFieldConfig.errorMessage}
              label={idFieldConfig.label}
              name="identifier"
              asText={asText}
              disabled={editMode && initialValues.publishedToHybris}
              description="you can not edit this after publishing"
              {...formControls}
            />
          </Form.Group>
          <Form.Group widths="equal">
            {access === ACCESS_LEVEL.all && (
              <Select
                validation={{ required: true }}
                errorMessage="Please pick a country"
                label="Country"
                name="country"
                options={countryOptions}
                asText={asText}
                search={true}
                hookIntoValueState={setSelectedCountry}
                {...formControls}
              />
            )}

            {selectedCountry !== '' && (
              <Select
                validation={{ required: true }}
                errorMessage="Please pick a language"
                label="Language"
                name="language"
                options={languageOptions.filter(lang =>
                  countryOptionsRaw.find(c => c.value === selectedCountry).lang.includes(lang.key)
                )}
                search={true}
                hookIntoValueState={setSelectedLanguage}
                selectedValue={selectedLanguage}
                asText={asText}
                {...formControls}
              />
            )}

            {!isBaas15Active && !isProductContent && pageType !== 'promos' && (
              <Select
                validation={{ required: true }}
                errorMessage="Please pick at least one option"
                label="Left navigation settings"
                name="navigation"
                options={templateOptions}
                multiple={false}
                asText={asText}
                {...formControls}
              />
            )}
          </Form.Group>

          <Select
            validation={{ required: true }}
            errorMessage="Please select a Content Department"
            label="Content Department"
            name="contentDepartment"
            options={contentDepartmentoptions}
            multiple={false}
            asText={asText}
            {...formControls}
          />

          {isProductContent && isNewShop(selectedCountry) && (
            <Select
              validation={{ required: false }}
              errorMessage="Please pick at least one option"
              label="Special Brand Selection"
              name="specialbrandname"
              options={specialBrandOptions}
              multiple={false}
              asText={asText}
              {...formControls}
            />
          )}
        </Form>
      </SubContainer>
    </React.Fragment>
  )
}
