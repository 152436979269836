import React, { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Pagination } from 'semantic-ui-react'
import ListItemClosed from './ListItemClosed'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'
import BriefingList from './BriefingList'

import { usePost } from '../../../hooks/restHooks'
import NothingHere from '../../../components/NothingHere'

const rowsPerPage = 15

const Wrapper = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
`

function ArchiveBriefingList(props) {
  const { searchterm, pagetype, reporter, page, country, ownBriefings, contentDepartment } = props
  const { data, loading, trigger } = usePost(`briefing/archive`)

  const myTrigger = () =>
    trigger({
      searchterm,
      reporter,
      pagetype: pagetype === 'all' ? undefined : pagetype,
      country: country === 'all' ? undefined : country,
      contentDepartment: contentDepartment === '' ? '' : contentDepartment,
      page,
      ownBriefings,
    })

  useEffect(() => {
    myTrigger()
  }, [searchterm, reporter, pagetype, page, country, ownBriefings, contentDepartment])

  const briefings = data ? data.briefings : []

  if (loading) {
    return <DelayedSpinner />
  }

  const ListItem = props => <ListItemClosed refetch={myTrigger} {...props} />

  return (
    <Wrapper>
      {briefings.length === 0 && <NothingHere />}
      {briefings.length !== 0 && (
        <>
          <BriefingList
            title={`Briefings (closed)`}
            icon="edit"
            briefings={briefings}
            ListItemComponent={ListItem}
            searchterm={searchterm}
            match={props.match}
            reporterFilter={reporter}
          />
        </>
      )}
    </Wrapper>
  )
}

function index(props) {
  const [page, setPage] = useState(1)
  const { searchterm, reporter, pagetype, country, ownBriefings, closedBriefingsCount, loading, contentDepartment } = props

  useEffect(() => {
    setPage(1)
  }, [searchterm, reporter, pagetype, country, ownBriefings, contentDepartment])

  if (loading) {
    return <DelayedSpinner />
  }

  const onPageChange = (e, { activePage }) => setPage(activePage)
  const totalPages = Math.max(Math.ceil(closedBriefingsCount / rowsPerPage), 1)

  return (
    <Wrapper>
      <ArchiveBriefingList page={page} {...props} />
      {totalPages > 1 && (
        <div style={{ marginTop: '3em', display: 'flex', justifyContent: 'center' }}>
          <Pagination defaultActivePage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </div>
      )}
    </Wrapper>
  )
}

export default memo(index)
