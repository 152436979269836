import React, { useState } from 'react'
import styled from 'styled-components'
import { Table, Dropdown } from 'semantic-ui-react'
import { countryOptions } from '@douglas/baas-shared'
import Layout from '../../Layout'
import { useGet } from '../../../hooks/restHooks'
import HeaderHr from '../../../components/semantic/HeaderHr'
import DelayedSpinner from '../../../components/semantic/DelayedSpinner'

const Container = styled.div`
  max-width: 1337px;
  margin: 0 auto;

  h2 {
    font-size: 30px;
    margin-bottom: 1em;
    margin-top: 1em;
  }

  td.locked {
    opacity: 0.4;
  }

  tr.locked td.edit {
    opacity: 1;
  }

  .hidden {
    visibility: hidden;
  }
`

export default function Statistics(props) {
  const [country, setCountry] = useState('')

  const { data, loading } = useGet(`admin/modules/all/${country}`, [country])

  const modules = data || []

  const changedCountry = (_, element) => {
    setCountry(element.value) // Setzt den neuen Wert, löst automatisch API-Call aus
  }

  if (loading) {
    return <DelayedSpinner />
  }

  return (
    <Layout showHr={false} style={{ paddingBottom: '1rem' }}>
      <Container>
        <HeaderHr style={{ marginBottom: '1rem', marginTop: '1rem' }} title={props.title} icon={'puzzle'} />
        <Dropdown
          validation={{ required: false }}
          placeholder="Filter country"
          selection
          label="Country"
          name="country"
          options={countryOptions}
          multiple={false}
          disabled={false}
          defaultValue={country}
          onChange={changedCountry}
        />

        <Table celled striped style={{ marginBottom: '3rem' }}>
          <Table.Body>
            <ModuleTable modules={modules} />
          </Table.Body>
        </Table>
      </Container>
    </Layout>
  )
}

function ModuleTable({ modules }) {
  return (
    <>
      <Table.Row active>
        <Table.Cell style={{ textAlign: 'right' }}>
          <b>Modulename</b>
        </Table.Cell>
        <Table.Cell style={{ textAlign: 'left' }}>
          <b>Number</b>
        </Table.Cell>
      </Table.Row>
      {modules.modules.map(({ module, count }) => (
        <Table.Row key={module}>
          <Table.Cell style={{ textAlign: 'right' }}>{module}</Table.Cell>
          <Table.Cell style={{ textAlign: 'left' }}>{count}</Table.Cell>
        </Table.Row>
      ))}
    </>
  )
}
